import React from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { useIntl } from "react-intl";

import IcnNext from "@assets/icons/rounded/next.svg";
import IcnPrev from "@assets/icons/rounded/previous.svg";
import { usePagination } from "@app/util/use-pagination";

import { IconComponent } from "../icon";
import styles from "./pagination-component.module.scss";

export interface IPaginationComponentProps {
  onChange?: (page: number) => void;
  currentPage: number;
  totalPages: number;
}

const Pagination: React.FunctionComponent<IPaginationComponentProps> = ({
  currentPage,
  onChange,
  ...rest
}: IPaginationComponentProps) => {
  const totalPages = Math.ceil(rest.totalPages || 0);
  const intl = useIntl();
  const { range, next, active, previous, setPage } = usePagination({
    total: Math.ceil(totalPages || 0),
    page: currentPage,
    initialPage: 1,
    onChange
  });

  return (
    <nav className={styles.pagination}>
      <div className={styles.skip}>
        <button type="button" onClick={previous} disabled={active === 1}>
          <IconComponent icon={IcnPrev} size="18px" fillColor="currentColor" />
          {intl.formatMessage({ id: "global.pagination.previous" })}
        </button>
      </div>
      <div className={styles.list}>
        {range.map((page, index) => (
          <React.Fragment key={index}>
            {page === "dots" ? (
              <span className={styles.dots}>...</span>
            ) : (
              <Link
                to="#"
                onClick={() => {
                  setPage(page);
                }}
                className={clsx(styles.number, active === page && styles.active)}
              >
                {page}
              </Link>
            )}
          </React.Fragment>
        ))}
      </div>
      <div className={clsx(styles.skip, styles.skipNext)}>
        <button type="button" onClick={next} disabled={active === totalPages}>
          {intl.formatMessage({ id: "global.pagination.next" })}
          <IconComponent icon={IcnNext} size="18px" fillColor="currentColor" />
        </button>
      </div>
    </nav>
  );
};

export { Pagination };
