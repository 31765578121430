import React, { useEffect, useState } from "react";
import { IntlShape, useIntl } from "react-intl";

import { DeleteModal } from "@app/modules/delete-modal/delete-modal.component";
import { ModalComponent } from "@app/core/modal";
import { UserFilterBar } from "@app/modules/user-filter-bar/user-filter-bar.component";
import { OutUserDTO } from "@app/api/generated";
import { UserModal } from "@app/modules/user-modal";
import { setCurrentPage, setSelectedUser } from "@app/redux/reducers/users";
import { useAppDispatch, useAppSelector } from "@app/redux/store";
import { usersThunks } from "@app/redux/thunks/users.thunk";
import { Column } from "@webbio/components";
import { Table } from "@app/components/table/table";
import { userRoles } from "@app/api/core/users/user";
import { Overview } from "@app/components/overview/overview";
import { TableActions } from "@app/core/table-actions";
import { useFilterState } from "@app/util/use-filter-state";

const TAKE = 18;

const UserOverview = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { users, isLoadingUsers, currentRole, totalResults, currentSearch, currentPage, selectedUser } = useAppSelector(
    (state) => state.users
  );

  const [totalPages, setTotalPages] = useState<number>(0);
  const [isDeleteUserOpen, setIsDeleteUserOpen] = useState<boolean>(false);
  const [userToDelete, setUserToDelete] = useState<OutUserDTO | undefined>(undefined);

  useFilterState({
    deps: { currentSearch, currentRole },
    onFirstChange: () => getUsers(currentPage),
    onChange: () => getUsers(1)
  });

  useEffect(() => {
    setTotalPages(totalResults / TAKE);
  }, [totalResults]);

  const onDeleteUser = (user: OutUserDTO) => {
    setUserToDelete(user);
    setIsDeleteUserOpen(true);
  };

  const onEditUser = async (user: OutUserDTO) => {
    if (user?.id) {
      dispatch(usersThunks.getUser(user.id));
    }
  };

  const onDeleteConfirmed = () => {
    if (userToDelete) {
      dispatch(usersThunks.deleteUser(userToDelete.id));
      setIsDeleteUserOpen(false);
      getUsers();
    }
  };

  const onPageChange = (page: number) => {
    getUsers(page);
  };

  const getUsers = (newPage?: number) => {
    const page = newPage || currentPage;
    const skip = (page - 1) * TAKE;

    dispatch(usersThunks.getUsers(skip, TAKE, currentSearch, currentRole));
    dispatch(setCurrentPage(page));

    window.scrollTo(0, 0);
  };

  const onTableRowClick = (user: OutUserDTO) => {
    onEditUser(user);
  };

  return (
    <Overview
      title={intl.formatMessage({ id: "userOverview.filterBar.users" })}
      actionBar={<UserFilterBar />}
      isLoading={isLoadingUsers}
      currentPage={currentPage}
      totalPages={totalPages}
      onPageChange={onPageChange}
      overviewItems={users}
    >
      {users && users.length > 0 && (
        <Table dataSource={users || []} isLoading={isLoadingUsers}>
          <Column
            title={getUserColumns(intl).company.title}
            field={getUserColumns(intl).company.field}
            id={getUserColumns(intl).company.id}
            onTdClick={onTableRowClick}
            cell={(company): JSX.Element => {
              return (
                <span className="truncate-table" title={company}>
                  {company || "-"}
                </span>
              );
            }}
          />

          <Column
            title={getUserColumns(intl).firstName.title}
            field={getUserColumns(intl).firstName.field}
            id={getUserColumns(intl).firstName.id}
            onTdClick={onTableRowClick}
            cell={(firstName) => {
              return <span className="truncate-table">{firstName || "-"}</span>;
            }}
          />

          <Column
            title={getUserColumns(intl).lastName.title}
            field={getUserColumns(intl).lastName.field}
            id={getUserColumns(intl).lastName.id}
            onTdClick={onTableRowClick}
            cell={(lastName) => {
              return <span className="truncate-table">{lastName || "-"}</span>;
            }}
          />

          <Column
            title={getUserColumns(intl).role.title}
            field={getUserColumns(intl).role.field}
            id={getUserColumns(intl).role.id}
            onTdClick={onTableRowClick}
            cell={(rowRole) => {
              const intlRole = userRoles.find((r) => r.apiName === rowRole?.name)?.name;

              return <span className="truncate-table">{intlRole ? intl.formatMessage({ id: intlRole }) : "-"}</span>;
            }}
          />

          <Column
            title=""
            field="id"
            cell={(_, idx) => {
              const user = users?.[idx as number];

              return <TableActions onEdit={() => onEditUser(user)} onDelete={() => onDeleteUser(user)} protectDelete />;
            }}
          />
        </Table>
      )}

      <ModalComponent
        title={intl.formatMessage({ id: "userOverview.confirmDelete" })}
        isModalOpen={isDeleteUserOpen}
        onCloseModal={() => setIsDeleteUserOpen(false)}
        variant="big"
      >
        <DeleteModal onCancel={() => setIsDeleteUserOpen(false)} onDelete={onDeleteConfirmed} />
      </ModalComponent>

      <ModalComponent
        title={intl.formatMessage({ id: "userOverview.editUser" })}
        isModalOpen={Boolean(selectedUser)}
        onCloseModal={() => dispatch(setSelectedUser())}
        variant="big"
      >
        <UserModal
          onCancel={() => dispatch(setSelectedUser())}
          onDone={() => {
            dispatch(setSelectedUser());
            getUsers();
          }}
        />
      </ModalComponent>
    </Overview>
  );
};

export { UserOverview };

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const getUserColumns = (intl: IntlShape) => ({
  company: {
    id: "company",
    title: intl.formatMessage({ id: "userOverview.column.company" }),
    field: "company"
  },
  firstName: {
    id: "firstName",
    title: intl.formatMessage({ id: "userOverview.column.firstName" }),
    field: "firstName"
  },
  lastName: {
    id: "lastName",
    title: intl.formatMessage({ id: "userOverview.column.lastName" }),
    field: "lastName"
  },
  role: {
    id: "role",
    title: intl.formatMessage({ id: "userOverview.column.role" }),
    field: "role"
  }
});
