import React, { useState } from "react";
import clsx from "clsx";
import { useIntl } from "react-intl";

import { ModalComponent } from "@app/core/modal";
import { DeleteModal } from "@app/modules/delete-modal/delete-modal.component";
import { OutProductDTO, PlatformDTO } from "@app/api/generated";
import { LabelComponent } from "@app/core";
import { useAppDispatch, useAppSelector } from "@app/redux/store";
import { Column } from "@webbio/components";
import { Table } from "@app/components/table/table";
import { ROUTES } from "@app/constants/routes";
import { platformTranslationKeys } from "@app/constants/platform";
import { productsThunk } from "@app/redux/thunks/products.thunk";
import { productsActions } from "@app/redux/reducers/products";
import { useDateFnsFormat } from "@app/util/use-date-fns-format";
import { TableActions } from "@app/core/table-actions";
import { Overview } from "@app/components/overview/overview";
import { useOnButtonLinkClick } from "@app/util/use-on-button-link-click";
import { useFilterState } from "@app/util/use-filter-state";

import { ProductFilterBar } from "./product-filter-bar/product-filter-bar.component";

const TAKE = 30;

const ProductOverview = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { products, isLoading, currentPage, currentPlatform, currentSearch, currentStatus, totalPages } =
    useAppSelector((state) => state.products);

  const { formatDate } = useDateFnsFormat();
  const { onButtonLinkClick } = useOnButtonLinkClick();
  const [isDeleteFilterOpen, setIsDeleteFilterOpen] = useState<boolean>(false);
  const [filterToDelete, setFilterToDelete] = useState<OutProductDTO | undefined>(undefined);

  useFilterState({
    deps: { currentSearch, currentStatus, currentPlatform },
    onFirstChange: () => getProduct(currentPage),
    onChange: () => getProduct(1)
  });

  const onDeleteProduct = (product: OutProductDTO) => {
    setFilterToDelete(product);
    setIsDeleteFilterOpen(true);
  };

  const onDeleteConfirmed = () => {
    if (filterToDelete) {
      dispatch(productsThunk.deleteProduct(filterToDelete.id));
      setIsDeleteFilterOpen(false);
    }
  };

  const onPageChange = (page: number) => {
    getProduct(page);
  };

  const getProduct = (newPage?: number) => {
    const page = newPage || currentPage;
    const skip = (page - 1) * TAKE;

    dispatch(productsThunk.getProducts(skip, TAKE, currentPlatform, currentSearch, currentStatus));
    dispatch(productsActions.setCurrentPage(page));

    window.scrollTo(0, 0);
  };

  const onTableRowAuxClick = (row: OutProductDTO, e: React.MouseEvent<HTMLTableCellElement, MouseEvent>) =>
    onTableRowClick(row, e, true);

  const onTableRowClick = (
    row: OutProductDTO,
    e: React.MouseEvent<HTMLTableCellElement, MouseEvent>,
    forceTargetBlank?: boolean
  ) => {
    const route = `${ROUTES.PRODUCTS}/${row.id}`;

    onButtonLinkClick(route, e, forceTargetBlank);
  };

  return (
    <Overview
      title={intl.formatMessage({ id: "products.overview.title" })}
      actionBar={<ProductFilterBar />}
      isLoading={isLoading}
      currentPage={currentPage}
      totalPages={totalPages}
      onPageChange={onPageChange}
      overviewItems={products}
    >
      {products && products.length > 0 && (
        <Table dataSource={products || []} isLoading={isLoading}>
          <Column
            title={intl.formatMessage({ id: "products.overview.table.th.status" })}
            field="live"
            id="live"
            onTdClick={onTableRowClick}
            onTdAuxClick={onTableRowAuxClick}
            maxWidth={80}
            width={80}
            cell={(live) => <LabelComponent type={live ? "live" : "offline"} />}
          />
          <Column
            title={intl.formatMessage({ id: "products.overview.table.th.title" })}
            field="title"
            id="title"
            onTdClick={onTableRowClick}
            onTdAuxClick={onTableRowAuxClick}
            cell={(title) => (
              <span className="truncate-table" title={title}>
                {title || "-"}
              </span>
            )}
          />
          <Column
            title={intl.formatMessage({ id: "products.overview.table.th.slug" })}
            field="slug"
            id="slug"
            onTdClick={onTableRowClick}
            onTdAuxClick={onTableRowAuxClick}
            cell={(slug) => (
              <span className="truncate-table" title={slug}>
                {slug || "-"}
              </span>
            )}
          />
          <Column
            title={intl.formatMessage({ id: "products.overview.table.th.platform" })}
            field="platform"
            id="platform"
            onTdClick={onTableRowClick}
            onTdAuxClick={onTableRowAuxClick}
            cell={(platform?: PlatformDTO) => {
              const translationKey = platformTranslationKeys.find((platformItem) => platformItem.id === platform?.id);
              const platformString = translationKey ? intl.formatMessage({ id: translationKey.name }) : "-";

              return (
                <span className="truncate-table" title={platformString}>
                  {platformString}
                </span>
              );
            }}
          />
          <Column
            title={intl.formatMessage({ id: "products.overview.table.th.updatedAt" })}
            field="updatedAt"
            id="updatedAt"
            onTdClick={onTableRowClick}
            onTdAuxClick={onTableRowAuxClick}
            maxWidth={200}
            width={200}
            cell={(updatedAt) => {
              const updatedAtByUser = updatedAt ? formatDate(updatedAt, "eeeeee. dd MMM yyyy HH:mm") : "";

              return <span className={clsx("truncate-table", "table-text-faded")}>{updatedAtByUser}</span>;
            }}
          />
          <Column
            title=""
            field="id"
            cell={(_, __, product: OutProductDTO) => {
              return (
                <TableActions editLink={`${ROUTES.PRODUCTS}/${product.id}`} onDelete={() => onDeleteProduct(product)} />
              );
            }}
          />
        </Table>
      )}
      <ModalComponent
        title={intl.formatMessage({ id: "products.overview.modal.delete.title" })}
        isModalOpen={isDeleteFilterOpen}
        onCloseModal={() => setIsDeleteFilterOpen(false)}
        variant="big"
      >
        <DeleteModal onCancel={() => setIsDeleteFilterOpen(false)} onDelete={onDeleteConfirmed} />
      </ModalComponent>
    </Overview>
  );
};

export { ProductOverview };
