import { NavigateFunction, useNavigate } from "react-router-dom";

const _onButtonLinkClick = (
  history: NavigateFunction,
  route: string,
  e: React.MouseEvent<HTMLTableCellElement, MouseEvent>,
  forceTargetBlank?: boolean
) => {
  if (e.metaKey || e.ctrlKey || forceTargetBlank) {
    window.open(route, "_blank");
  } else {
    history(route);
  }
};

const useOnButtonLinkClick = () => {
  const history = useNavigate();

  const onButtonLinkClick = (
    route: string,
    e: React.MouseEvent<HTMLTableCellElement, MouseEvent>,
    forceTargetBlank?: boolean
  ) => _onButtonLinkClick(history, route, e, forceTargetBlank);

  return { onButtonLinkClick };
};

export { useOnButtonLinkClick };
