import { useEffect, useMemo } from "react";

import { usePrevious } from "./use-previous";

type FilterStateProps = {
  deps: Record<string, any>;
  onChange?: () => void;
  onFirstChange?: () => void;
};

const useFilterState = ({ deps, onFirstChange, onChange }: FilterStateProps) => {
  const filterState = useMemo(() => JSON.stringify(deps), [deps]);

  const prevFilterState = usePrevious(filterState);

  useEffect(() => {
    if (prevFilterState === filterState) return;

    if (!prevFilterState) {
      onFirstChange?.();
      return;
    }

    if (prevFilterState && prevFilterState !== filterState) {
      onChange?.();
    }
  }, [filterState]);

  return {
    filterState,
    prevFilterState
  };
};

export { useFilterState };
