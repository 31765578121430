import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { orderBy } from "lodash";
import clsx from "clsx";

import { DeleteModal } from "@app/modules/delete-modal/delete-modal.component";
import { BannerFilterBar } from "@app/modules/banner-filter-bar/banner-filter-bar.component";
import { ResourceTextComponent, ModalComponent } from "@app/core";
import { Table } from "@app/components/table/table";
import { Column, ISortDirection } from "@webbio/components";
import { platformTranslationKeys } from "@app/constants/platform";
import { useAppDispatch, useAppSelector } from "@app/redux/store";
import { bannersThunks } from "@app/redux/thunks/banners.thunk";
import { setCurrentPage } from "@app/redux/reducers/banners";
import { BannersExtendedGetStatusEnum, OutBannerDTO } from "@app/api/generated";
import { useDateFnsFormat } from "@app/util/use-date-fns-format";
import { TableActions } from "@app/core/table-actions";
import { ROUTES } from "@app/constants/routes";
import { Overview } from "@app/components/overview/overview";
import { useOnButtonLinkClick } from "@app/util/use-on-button-link-click";
import { useFilterState } from "@app/util/use-filter-state";

import { BannerStatusIcon } from "./banner-status-icon/banner-status-icon.component";
import styles from "./banner-overview-component.module.scss";

const TAKE = 18;

const BannerOverview = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { banners, isLoadingBanners, totalResults, currentStatus, currentSearch, currentPlatform, currentPage } =
    useAppSelector((state) => state.banners);
  const { onButtonLinkClick } = useOnButtonLinkClick();
  const { formatDate } = useDateFnsFormat();

  const [totalPages, setTotalPages] = useState<number>(0);
  const [isDeleteBannerOpen, setIsDeleteBannerOpen] = useState<boolean>(false);
  const [bannerToDelete, setBannerToDelete] = useState<OutBannerDTO | undefined>(undefined);
  const [deletedBanner, setDeletedBanner] = useState<OutBannerDTO | undefined>(undefined);
  const [sortedBanners, setSortedBanners] = useState<OutBannerDTO[]>(banners);

  useFilterState({
    deps: { currentPlatform, currentSearch, currentStatus },
    onFirstChange: () => getBanners(currentPage),
    onChange: () => getBanners(1)
  });

  useEffect(() => {
    setSortedBanners(banners);
  }, [banners]);

  useEffect(() => {
    setTotalPages(totalResults / TAKE);
  }, [totalResults]);

  const onPageChange = (page: number) => {
    getBanners(page);
  };

  const getBanners = (newPage?: number) => {
    const page = newPage || currentPage;
    const skip = (page - 1) * TAKE;

    dispatch(bannersThunks.getBanners(skip, TAKE, currentStatus, currentSearch, currentPlatform));
    dispatch(setCurrentPage(page));

    window.scrollTo(0, 0);
  };

  const onDeleteBanner = (banner: OutBannerDTO) => {
    setBannerToDelete(banner);
    setIsDeleteBannerOpen(true);
  };

  const onDeleteConfirmed = () => {
    if (bannerToDelete) {
      setDeletedBanner(bannerToDelete);

      setTimeout(() => {
        dispatch(bannersThunks.deleteBanner(bannerToDelete.id));
      }, 700);
    }
    setIsDeleteBannerOpen(false);
  };

  const handleSort = (columnId?: string, sortDirection?: ISortDirection) => {
    if (columnId === "startDate") {
      if (!sortDirection) {
        setSortedBanners(banners);
      } else {
        const newSortedBanners = orderBy(banners, "startDate", sortDirection.toLocaleLowerCase() as "asc" | "desc");

        setSortedBanners(newSortedBanners);
      }
    }
  };

  const onTableRowAuxClick = (row: OutBannerDTO, e: React.MouseEvent<HTMLTableCellElement, MouseEvent>) =>
    onTableRowClick(row, e, true);

  const onTableRowClick = (
    row: OutBannerDTO,
    e: React.MouseEvent<HTMLTableCellElement, MouseEvent>,
    forceTargetBlank?: boolean
  ) => {
    const route = `${ROUTES.BANNER_DETAIL}/${row.id}`;

    onButtonLinkClick(route, e, forceTargetBlank);
  };

  return (
    <div>
      <Overview
        title={intl.formatMessage({ id: "bannerFilterBar.title" })}
        actionBar={<BannerFilterBar />}
        isLoading={isLoadingBanners}
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={onPageChange}
        overviewItems={sortedBanners}
        footer={
          <div className={styles.legend}>
            <div className={styles.legendItem}>
              <span className={styles.icon}>
                <BannerStatusIcon status={BannersExtendedGetStatusEnum.VISIBLE} />
              </span>
              <span className={styles.text}>{intl.formatMessage({ id: "global.banner.status.visible" })}</span>
            </div>
            <div className={styles.legendItem}>
              <span className={styles.icon}>
                <BannerStatusIcon status={BannersExtendedGetStatusEnum.ALMOST_VISIBLE} />
              </span>
              <span className={styles.text}>{intl.formatMessage({ id: "global.banner.status.almostVisible" })}</span>
            </div>
            <div className={styles.legendItem}>
              <span className={styles.icon}>
                <BannerStatusIcon status={BannersExtendedGetStatusEnum.INVISIBLE} />
              </span>
              <span className={styles.text}>{intl.formatMessage({ id: "global.banner.status.invisible" })}</span>
            </div>
          </div>
        }
      >
        {sortedBanners && sortedBanners.length > 0 && (
          <Table
            onSort={handleSort}
            defaultSort={{
              column: "startDate",
              direction: undefined
            }}
            dataSource={sortedBanners}
            isLoading={isLoadingBanners}
          >
            <Column
              field="status"
              title={<ResourceTextComponent resourceKey="bannerOverview.tableHeader.status" />}
              id="status"
              isNotSortable
              onTdClick={onTableRowClick}
              onTdAuxClick={onTableRowAuxClick}
              cell={(status, index) => {
                return (
                  <span className={clsx(deletedBanner?.id === sortedBanners[index as number]?.id && styles.isDeleted)}>
                    <BannerStatusIcon status={status} />
                  </span>
                );
              }}
            />
            <Column
              field="name"
              title={<ResourceTextComponent resourceKey="bannerOverview.tableHeader.title" />}
              id="title"
              isNotSortable
              onTdClick={onTableRowClick}
              onTdAuxClick={onTableRowAuxClick}
              cell={(title, index) => {
                return (
                  <span className={clsx(deletedBanner?.id === sortedBanners[index as number]?.id && styles.isDeleted)}>
                    {title}
                  </span>
                );
              }}
            />
            <Column
              field="startDate"
              title="Startdatum"
              id="startDate"
              sortField="startDate"
              onTdClick={onTableRowClick}
              onTdAuxClick={onTableRowAuxClick}
              cell={(startDate, index) => {
                const bannerStartDate = startDate ? formatDate(startDate) : "-";

                return (
                  <span className={deletedBanner?.id === sortedBanners[index as number].id ? styles.isDeleted : ""}>
                    {bannerStartDate}
                  </span>
                );
              }}
            />
            <Column
              field="endDate"
              title="Einddatum"
              id="endDate"
              onTdClick={onTableRowClick}
              onTdAuxClick={onTableRowAuxClick}
              isNotSortable
              cell={(endDate, index) => {
                const bannerEndDate = endDate ? formatDate(endDate) : "-";

                return (
                  <span className={deletedBanner?.id === sortedBanners[index as number].id ? styles.isDeleted : ""}>
                    {bannerEndDate}
                  </span>
                );
              }}
            />
            <Column
              field="platform"
              title="Platform"
              id="platform"
              onTdClick={onTableRowClick}
              onTdAuxClick={onTableRowAuxClick}
              isNotSortable
              cell={(platform, index) => {
                const platformString = platformTranslationKeys.find((pf) => pf.id === platform.id)?.name;
                const formattedPlatform = intl.formatMessage({
                  id: platformString || "global.platform.unknown"
                });

                return (
                  <span className={clsx(deletedBanner?.id === sortedBanners[index as number]?.id && styles.isDeleted)}>
                    {formattedPlatform}
                  </span>
                );
              }}
            />
            <Column
              title=""
              field="id"
              isNotSortable
              cell={(_, index) => {
                const banner = sortedBanners[index as number];

                return (
                  <TableActions
                    editLink={`${ROUTES.BANNER_DETAIL}/${banner.id}`}
                    onDelete={() => onDeleteBanner(banner)}
                  />
                );
              }}
            />
          </Table>
        )}

        <ModalComponent
          title={intl.formatMessage({ id: "bannerOverview.modal.deleteBanner.title" })}
          isModalOpen={isDeleteBannerOpen}
          onCloseModal={() => {
            setIsDeleteBannerOpen(false);
          }}
          variant="big"
        >
          <DeleteModal
            onCancel={() => {
              setIsDeleteBannerOpen(false);
            }}
            onDelete={onDeleteConfirmed}
          />
        </ModalComponent>
      </Overview>
    </div>
  );
};

export { BannerOverview };
